






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Link } from '@/models'
import PaginatedList from '@/components/PaginatedList.vue'
import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import { links } from '@/components/componentTypes/links'

@Component({
  components: {
    PaginatedList,
    ComponentCreateDialog
  }
})
export default class AdminSettingsNavigation extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string

  componentType = links

  saving = false

  createModalOpen = false

  /** Open or close the creation modal */
  toggleCreateModal (value : any) {
    this.createModalOpen = value != null && typeof value === 'boolean' ? value : !this.createModalOpen
  }

  handleCreate (component : any) {
    if (component && component._id) {
      return this.$router.push(`/${this.environmentId}/components/${this.componentType.namespace}/${component._id}`)
    }
  }
}
